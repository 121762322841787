<script>
import { productFruits } from 'product-fruits'

import featureNames from '@/lib/featureNames'

import IDENTITY from '../queries/Identity.gql'

export default {
  data: () => ({
    identity: null,
    isInitialized: false,
    feature: null,
  }),

  watch: {
    identity(identity) {
      if (identity && !this.isInitialized) {
        this.init()
        this.isInitialized = true
      }
    },
  },

  created() {
    this.feature = this.$features.feature(featureNames.PRODUCTFRUITS)
  },

  methods: {
    init() {
      const { user, company } = this.identity
      productFruits.init(
        this.feature.config.workspaceCode,
        this.$i18n.locale.slice(0, 2),
        {
          email: user.email,
          username: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          signUpAt: user.createdAt,
          role: user.role?.slug,
          props: {
            gender: user.gender,
            countryCode: company.country?.toUpperCase(),
            companySlug: company.slug,
            companyName: company.name,
            productType: company.productType,
          },
        },
        {
          customNavigation: {
            use: true,
            navigate: (location) => {
              if (location.startsWith('http')) {
                window.open(location, '_self')
              } else {
                this.$router.push(location)
              }
            },
            onGet() {
              return window.location.href
            },
          },
        }
      )

      this.injectStylesIntoShadowRoot()
    },

    injectStylesIntoShadowRoot() {
      setTimeout(() => {
        const shadowHost = document.querySelector('.productfruits--container')
        if (shadowHost && shadowHost.shadowRoot) {
          const shadowRoot = shadowHost.shadowRoot

          const style = document.createElement('style')
          style.textContent = this.$vuetify.display.mdAndUp
            ? `.pfruits-widget-newsfeed {
              max-height: 500px;
              min-width: 450px;
            }`
            : ``

          shadowRoot.appendChild(style)
        } else {
          console.warn('Shadow root not found. Ensure productFruits is initialized properly.')
        }
      }, 2000)
    },

    useProductFruits() {
      return this.feature?.isActive && this.feature.config.workspaceCode
    },
  },

  apollo: {
    identity: {
      query: IDENTITY,
      skip() {
        return (
          !this.useProductFruits() ||
          this.$auth.loading ||
          !this.$auth.isAuthenticated ||
          !this.$auth.user.companyId
        )
      },
    },
  },

  render() {
    return null
  },
}
</script>

<style scoped>
@media (min-width: 960px) {
  :deep(.pfruits-widget-newsfeed) {
    max-height: 500px;
  }
}
</style>
